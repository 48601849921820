import $ from "jquery";
import 'slick-carousel';
import ScrollMagic from "scrollmagic";
import debounce from "throttle-debounce/debounce";
import {TweenLite, TweenMax,  Expo} from 'gsap';
/*eslint-disable */
import "jquery-hoverintent/jquery.hoverIntent.js";


function initHero() {

  let $menu = $('.element-container .element');
  let $menuDefault = $('.element-container .element.default');
  let $container = $('.element-container');

  let heroContainerHeight = $container.parent('.hero-container').height();

  //disable-quick-view if viewport is less than 992
  if ($(window).width() < 992) {
    $container.addClass('disable-animation');
    $menu.find('.image img').css({'height':'auto'});
    $menu.find('.caption a').attr('tabindex','0');
  }
  else {
    $container.removeClass('disable-animation');
    $menu.find('.image img').height(heroContainerHeight);
  }

  // debounce resize functions if viewport is less than 992
  $(window).on('resize', debounce(250, function (){
    let $viewport = $(window).width();
    if ($viewport <= 991) {
      $container.addClass('disable-animation');
      $menuDefault.removeClass('menu-open');
      $menu.find('.image img').css({'height':'auto'});
      $menu.removeAttr("style");
      $menu.find('.caption a').attr('tabindex','0');
    }
    else {
      $container.removeClass('disable-animation');
      let recaculateContainerHeight = $container.parent('.hero-container').height();
      $menu.find('.image img').height(recaculateContainerHeight);
    }
  }));

  function menuOpen() {
    let $this = $(this);

    $menu.removeClass('menu-open');
    $menu.attr('aria-expanded', 'false'); // accessibility
    TweenLite.to($menu, 1, {width: "28%", ease: Expo.easeOut});

    $this.addClass('menu-open');
    $this.attr('aria-expanded', 'true'); // accessibility
    TweenLite.to($this, 1, {width: "46%", ease: Expo.easeOut});
  }

  function menuReset() {
    TweenLite.to($menu, 1, {width: "33.33%", ease: Expo.easeOut});
    $menu.removeClass('menu-open');

    //$menuDefault.addClass('menu-open');
   // $menuDefault.attr('aria-expanded', 'true');
    TweenLite.to($menuDefault, 1, {width: "33.33%", ease: Expo.easeOut});
  }

  if ($container.length) {
    if (!($container.is('.disable-animation'))) {
      // on focus on
      $menu.bind('focusin', menuOpen);
      // on mouse enter
      $menu.hoverIntent({
        over: menuOpen,
        out: menuReset,
      });
      // on mouse leave, focus out
      $container.bind('mouseleave focusout', menuReset);
    }

    // debounce resize functions if viewport is less than 992
    $(window).on('resize', debounce(250, function (){
      let $viewport = $(window).width();
      if ($viewport <= 991) {
          $menu.unbind("focusin").unbind("mouseenter").unbind("mouseleave");
          $container.unbind("mouseleave").unbind("focusout");
      }
      else {
          // on focus on
          $menu.bind('focusin', menuOpen);
          // on mouse enter
          $menu.hoverIntent({
              over: menuOpen,
              out: menuReset,
          });
          // on mouse leave, focus out
          $container.bind('mouseleave focusout', menuReset);
      }
    }));
  }

}

function initOverFlowCarousel() {

    if ($('.overflow-carousel').length) {

        let $carouselCopy = $('.overflow-carousel .carousel-copy');
        let $carouselCount = $('.overflow-carousel .carousel-count');

        $('.overflow-carousel .carousel-images').slick({
        slidesToScroll: 1,
        arrows: true,
        prevArrow: '<a href="#" class="btn-outline btn-pagination btn-arrow btn-arrow-prev slick-prev-btn" aria-label="Previous" onclick="this.blur();"><span class="sr-only">Previous</span></a>',
        nextArrow: '<a href="#" class="btn-outline btn-pagination btn-arrow btn-arrow-next slick-next-btn" aria-label="Next" onclick="this.blur();"><span class="sr-only">Next</span></a>',
        fade: false,
        dots: false,
        swipe:false,
        infinite: true,
        variableWidth: true,
        asNavFor: '.carousel-copy',
    });

        $carouselCopy.on('init reInit afterChange', function (event, slick, currentSlide) {
        //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
        let i = (currentSlide ? currentSlide : 0) + 1;
        $carouselCount.text(i + ' of ' + slick.slideCount);
    });

        $carouselCopy.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows:false,
        fade: true,
        speed: 500,
        cssEase: 'ease',
        swipe:false,
        asNavFor: '.carousel-images',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: true,
                    prevArrow: '<a href="#" class="btn-outline btn-pagination btn-arrow btn-arrow-prev slick-prev-btn" aria-label="Previous" onclick="this.blur();"><span class="sr-only">Previous</span></a>',
                    nextArrow: '<a href="#" class="btn-outline btn-pagination btn-arrow btn-arrow-next slick-next-btn" aria-label="Next" onclick="this.blur();"><span class="sr-only">Next</span></a>',
                }
            }
        ]
        });

    $(window).on('resize orientationchange', function() {
        $('.overflow-carousel .carousel-images').slick('resize');
    });
    }
}

function initLazyLoading() {

    let $mainContainer = $(".main-container");
    let $moduleContent = $(".lazyLoad");
    if ($mainContainer.length) {

        // create the scrollMagic controller
        let controller;
        controller = new ScrollMagic.Controller();

        // if ($.browser.mobile) {
        //   controller.destroy();
        // } else {
        $($moduleContent).each(function () {
            let $this = $(this);
            new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: 1
            })
                .on("enter", function () {
                    $this.addClass("animated fadeInUp");
                })
                .addTo(controller);
        });
        // }
    }
}

function initT1Navigation() {

    let $trigger = $('.tier-menu .has-menu'); // only items with class .has-menu will trigger
    let $menu = $('.category-menu-container .collapsed-menu');
    let $close = $('.category-menu-container .close-menu');

    // close menu function
    function closeNavMenu(e) {
        $menu.slideUp(function () {
            $menu.removeClass('menu-open');
            $('.menu--main .has-menu.active a').focus();
            $trigger.removeClass('active');
            $trigger.find('a').attr('aria-expanded', 'false'); // accessibility

            if ($('.home-menu-wrapper .home-menu').length) {
                $('.main-menu-wrapper').css('opacity','0').attr('aria-hidden', "true");

                $('.main-menu-wrapper a').attr('tabindex', "-1").css('pointer-events', 'none');

                $('.home-menu-wrapper').css('opacity','1');
            }
        });
        e.preventDefault();
    }

    if ($trigger.length) {
        $trigger.on('click', function (e) {
            let $this = $(this);
            let target = $this.find('> a').data('target');
            let tabbable = $(target).find('select, input, textarea, button, a');
            let firstTabbable = tabbable.first();
            let lastTabbable = tabbable.last();

            // check if t1 menu is currently animating - prevent clicks
            if ($trigger.is('.animating')) {
                e.stopPropagation();
            }
            // check if t1 menu is already open - if it is close menu
            else if ($this.hasClass('active')) {
                $this.removeClass('active');
                $this.find('a').attr('aria-expanded', 'false'); // accessibility
                $(target).slideUp(function () {
                    $(target).removeClass('menu-open');
                });
            }
            // check if t1 menu is open while another nav item is clicked - close current menu and open the new one
            else if ($menu.is('.menu-open:visible')) {
                let $menuOpen = $menu.closest('.menu-open');
                $trigger.removeClass('active');
                $trigger.find('a').attr('aria-expanded', 'false'); // accessibility
                $this.addClass('animating');
                $menuOpen.slideUp({
                    complete: function () {
                        $menu.removeClass('menu-open');
                        $(target).slideDown(function () {
                            $(target).addClass('menu-open').focus();
                            $trigger.removeClass('animating');
                        });
                        $this.addClass('active');
                        $this.find('a').attr('aria-expanded', 'true'); // accessibility
                        setTimeout(function (){
                            firstTabbable.focus();
                        }, 401);
                    }
                });
            }
            // open targeted menu
            else {
                $this.addClass('animating');
                $(target).slideDown(function () {
                    $(target).addClass('menu-open').focus();
                    $trigger.removeClass('animating');
                });
                $this.addClass('active');
                $this.find('a').attr('aria-expanded', 'true'); // accessibility
                setTimeout(function (){
                    firstTabbable.focus();
                }, 201);

                // Add ESC key functionality to close visible menu
                $(document).keyup(function (e) {
                    if (e.keyCode === 27) {
                        closeNavMenu();
                    }
                    e.preventDefault();
                });
            }
            e.preventDefault();

            /*redirect last tab to first input*/
            lastTabbable.on('keydown', function (e) {
                if ((e.which === 9 && !e.shiftKey)) {
                    e.preventDefault();
                    firstTabbable.focus();
                }
            });
            /*redirect first shift+tab to last input*/
            firstTabbable.on('keydown', function (e) {
                if ((e.which === 9 && e.shiftKey)) {
                    e.preventDefault();
                    lastTabbable.focus();
                }
            });
        });

        // close menu
        $close.on('click', closeNavMenu);
    }

    if ($('.home .home-menu-wrapper .home-menu').length) {
        $('.main-menu-wrapper').attr('aria-hidden','true');
        $('.main-menu-wrapper a').attr('tabindex','-1');
        $('.main-menu-wrapper a').css('pointer-events','none');

        $('.home-menu .has-menu').on('click', function (e) {
            let $this = $(this);
            let target = $this.find('a').data('target');
            let $clicked = $trigger.find('[data-target="'+ target +'"]');

            $clicked.trigger("click");

            $('.main-menu-wrapper').css('opacity','1').attr('aria-hidden','false');

            $('.main-menu-wrapper a').attr('tabindex','0').css('pointer-events', 'inherit');

            $('.home-menu-wrapper').css('opacity','0');
        });
    }
}

function initMobileMenu(){
    let $menu = $('.mobile-menu-container .menu');
    let $close = $('.mobile-menu-container .close-menu');
    let $overlay = $('#page-overlay');

    let $mobileSearchTrigger = $('.navbar-toggler'); // only items with class .has-menu will trigger

    if ($mobileSearchTrigger.length) {
        $mobileSearchTrigger.on('click', function (e) {
            let $this = $(this);
            let target = $this.data('target');
            let tabbable = $(target).find('select, input, textarea, button, a');
            let firstTabbable = tabbable.first();
            let lastTabbable = tabbable.last();

            if ($this.hasClass('active')){
                $(target).hide();
                $(target).removeClass('menu-open');
                $this.removeClass('active');
                $this.attr('aria-expanded','false');
                $this.find('span').text('Menu');
                $overlay.fadeOut();
            }
            else if ($menu.is('.menu-open:visible')) {
                let $menuOpen = $menu.closest('.menu-open');

                $mobileSearchTrigger.find('a').attr('aria-expanded','false');
                $overlay.hide();
                $menuOpen.hide();
                $mobileSearchTrigger.removeClass('active');
                $menu.removeClass('menu-open');
                $(target).delay(400).fadeIn();
                $(target).addClass('menu-open');
                $overlay.fadeIn();
                $this.addClass('active');
                $this.attr('aria-expanded','true');
                $this.find('span').text('Close');

                setTimeout(function (){
                    firstTabbable.focus();
                }, 401);
            }
            else {
                $(target).delay(200).fadeIn();
                $(target).addClass('menu-open');
                $overlay.fadeIn();
                $this.addClass('active');
                $this.attr('aria-expanded','true');
                $this.find('span').text('Close');
                setTimeout(function (){
                    firstTabbable.focus();
                }, 201);

            }
            e.preventDefault();
            /*redirect last tab to first input*/
            lastTabbable.on('keydown', function (e) {
                if ((e.which === 9 && !e.shiftKey)) {
                    e.preventDefault();
                    firstTabbable.focus();
                }
            });
            /*redirect first shift+tab to last input*/
            firstTabbable.on('keydown', function (e) {
                if ((e.which === 9 && e.shiftKey)) {
                    e.preventDefault();
                    lastTabbable.focus();
                }
            });
        });

        $close.on('click', function (e) {
            $menu.fadeOut();
            $menu.removeClass('menu-open');
            $('.t1-nav .has-menu.active').find('a').focus();
            $mobileSearchTrigger.removeClass('active');
            $mobileSearchTrigger.find('a').attr('aria-expanded','false');
            $mobileSearchTrigger.find('a span').text('Menu');
            $overlay.fadeOut();
            e.preventDefault();
        });
    }

    $(document).on('click', '.navbar-toggler', function () {
        if ($(this).hasClass('active')) {
            $('.mobile-menu-container').css({'z-index':'3','display':'block'});
            $("body").css('overflow','hidden');
            $("html").css('position','fixed');
        }
        else {
            $('.mobile-menu-container').css({'z-index':'-1', 'display':'none'});
            $("body").css('overflow','visible');
            $("html").css('position','static');

        }
    });

    let $mobileTrigger = $('.mobile-menu-container .has-menu'); // only items with class .has-menu will trigger
    let $t1Menu = $('.mobile-menu-container .t1-menu.mobile');
    let $t2Menu = $('.slide-menu-container .t2-menu');
    let $t3Menu = $('.slide-menu-container .t3-menu');
    let $mobileClose = $('.slide-menu-container > .collapsed-menu .back-menu');

    // close menu function
    function closeNavMenu(e){

        if($(this).parents('.slide').hasClass('t2-menu')) {
            $t1Menu.fadeIn();
            $t2Menu.fadeOut();
            TweenMax.to($t2Menu,0.5, {right:"-100%", ease:Expo.easeOut, onComplete:function (){
                    $t2Menu.removeClass('menu-open');
                    $mobileTrigger.removeClass('active');
                    $mobileTrigger.find('> a').attr('aria-expanded', 'false'); // accessibility
                    $t2Menu.attr({'aria-hidden': 'true', 'aria-expanded': 'false'}); // accessibility
                    $t1Menu.focus(); // accessibility
                }});
        }
        if($(this).parents('.slide').hasClass('t3-menu')) {
            $t2Menu.fadeIn();
            $t3Menu.fadeOut();
            TweenMax.to($t3Menu,0.5, {right:"-100%", ease:Expo.easeOut, onComplete:function (){
                    $t3Menu.removeClass('menu-open');
                    $mobileTrigger.removeClass('active');
                    $mobileTrigger.find('> a').attr('aria-expanded', 'false'); // accessibility
                    $t3Menu.attr({'aria-hidden': 'true', 'aria-expanded': 'false'}); // accessibility
                    $t2Menu.focus(); // accessibility
                }});
        }
        e.preventDefault();
    }

    // close menu function
    function resetMenu(){
        $t2Menu.removeClass('menu-open').css({'right':'-100%'});
        $t3Menu.removeClass('menu-open').css({'right':'-100%'});
        $mobileTrigger.removeClass('active');
        $mobileTrigger.find('> a').attr('aria-expanded', 'false'); // accessibility
        $t3Menu.attr({'aria-hidden': 'true', 'aria-expanded': 'false'}); // accessibility
        $t2Menu.attr({'aria-hidden': 'true', 'aria-expanded': 'false'}); // accessibility
        $t1Menu.show();
        $t2Menu.hide();
        $t3Menu.hide();
        // reset container height prior to checkForMenuOverflow function
        $('.t1-menu-container').css('height', 'auto');
    }

    if ($mobileTrigger.length) {
        $mobileTrigger.on('click', function (e) {
            let $this = $(this);
            let target = $this.find('> a').data('target');

            if($mobileTrigger.is('.animating')){
                e.stopPropagation();
            }
            // open targeted menu
            else {
                $this.addClass('animating');
                $t1Menu.fadeOut();
                if($mobileTrigger.parents('.slide').hasClass('t2-menu')) {
                    $t2Menu.fadeOut();
                }

                $(target).fadeIn();
                TweenMax.to(target,0.5, {right: "0", ease: Expo.easeInOut, onComplete: function () {
                        $(target).addClass('menu-open');
                        $(target).attr({'aria-hidden': 'false', 'aria-expanded': 'true'}); // accessibility
                        $mobileTrigger.removeClass('animating');
                    }});
                $this.addClass('active');
                $this.find('> a').attr('aria-expanded', 'true'); // accessibility
            }
            e.preventDefault();

            // Add ESC key functionality to close visible menu
            $(document).keyup(function (e) {
                if (e.keyCode === 27) {$mobileClose.click();}
            });
        });
        // close menu
        $mobileClose.on('click', closeNavMenu);
    }

    // reset menus on main menu close
    $mobileSearchTrigger.on('click', function (){
        resetMenu();
    });
}

function initStickyFooterbtn() {

    let controller = new ScrollMagic.Controller();
    // detect window width on load/resize
    let $viewportWidth = $(window).width();
    let $footerBtn = $('.sub-footer');

    // random variables to stop redundant events
    let var1 = 0;
    let var2 = 0;

    // set defaults for scroll position/direction
    let lastDir = 'PAUSED';
    let scrollingPos = 0;

    function peekABooHeader(){
        let scrollDirection = controller.info('scrollDirection');

        // if the direction switches, change the position where you currently are for calculations later on
        if (lastDir !== scrollDirection) {
            lastDir = scrollDirection;
            scrollingPos = $(window).scrollTop();
        }

        let scrollDistance = Math.abs(scrollingPos - $(window).scrollTop());

        if ($viewportWidth >= 992 && scrollDirection === 'REVERSE' && scrollDistance >= 300 && var1 === 0) {
            $footerBtn.removeClass('static');
            $footerBtn.addClass('fixed');
            $footerBtn.addClass('animate-in');

            var1++;
            var2 = 0;
        }

        if ($viewportWidth >= 992 && scrollDirection === 'FORWARD' && var2 === 0) {
            $footerBtn.removeClass('fixed');
            $footerBtn.removeClass('animate-in');

            var1 = 0;
            var2++;
        }
    }

    if ($('.sub-footer').length) {
    // sticky nav slide down on scroll up - destroy on mobile
        if ($(window).width() <= 991) {
            controller.destroy();
        } else {

            let stickyHeader = new ScrollMagic.Scene({
                triggerElement: '.header-container',
                triggerHook: '0',
                duration: $footerBtn.height()
            })

                .on('start', function () {
                    $footerBtn.removeClass('static');
                    $footerBtn.addClass('fixed');
                    $footerBtn.addClass('animate-in');
                    console.log('start');
                })

                .on('enter', function (event) {
                    console.log('enter');
                    let direction = event.scrollDirection;
                    $footerBtn.addClass('static');

                    if(direction === "REVERSE") {
                        $footerBtn.removeClass('fixed');
                        $footerBtn.removeClass('animate-in');
                    }
                })

                .on('leave', function () {
                    stickyHeader.on('update', peekABooHeader);

                    console.log('leave');
                })

                .setPin('.sub-footer', {spacerClass:'footer-pin', pushFollowers: false})
                .addTo(controller);
        }

        $(window).on('resize', debounce(250, function (){
            if ($(window).width() <= 991) {
                controller.enabled(false);
            } else if (!controller.enabled()) {
                controller.enabled(true);
            }
            controller.update(true);
        }));
    }
}

function initClinicalTrialCarousel() {

    if ($('.clinical-trial-carousel').length) {
        $('.clinical-trial-carousel .carousel').slick({
            slidesToScroll: 1,
            arrows: true,
            prevArrow: '<a href="#" class="btn-pagination btn-arrow btn-arrow-prev slick-prev-btn" aria-label="Previous" onclick="this.blur();"><span class="sr-only">Previous</span></a>',
            nextArrow: '<a href="#" class="btn-pagination btn-arrow btn-arrow-next slick-next-btn" aria-label="Next" onclick="this.blur();"><span class="sr-only">Next</span></a>',
            fade: false,
            dots: false,
            swipe:false,
            infinite: false
        });
    }
}

function initUpdateCarouselButtons() {
    const $carousel = $(".carousel");

    // Look to see if .carousel is on page...
    if ($carousel.length) {
        $carousel.each( function () {
            $(this).find('.slick-prev-btn').on('click', function () {
                this.blur();
            });
            $(this).find('.slick-next-btn').on('click', function () {
                this.blur();
            });
        });
    }
}

function initAccordionCarousel() {
    $('#two-col-carousel-module button').on('click', function() {
        $(".slick-slider").slick("refresh");
    });
}


$(document).ready(function () {
  initHero();
  initOverFlowCarousel();
  initLazyLoading();
  initT1Navigation();
  initMobileMenu();
  initStickyFooterbtn();
  initClinicalTrialCarousel();
  initUpdateCarouselButtons();
  initAccordionCarousel();
});
